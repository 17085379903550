<template>
  <div>
    <WaBtn />
    <!-- header -->
    <div class="py-4 bg-white">
      <div class="container">
        <div class="md:flex md:justify-between md:items-center">
          <div class="md:w-1/2 lg:w-1/2 xl:w-2/5">
            <img src="/images/main-logo.png" alt="" class="" />
          </div>
          <div class="flex mt-4 md:space-x-1">
            <div class="w-1/2">
              <p class="text-lg font-semibold text-center text-gray-700">WhatsApp Us</p>
              <p
                class="w-40 py-2 font-semibold text-center text-gray-700 transition duration-200 ease-linear rounded-lg hover:text-white hover:bg-sri-peach hover:shadow-md bg-sri-orange">
                <a href="https://wa.me/60162172172">016-217 2172</a>
              </p>
            </div>
            <div class="w-1/2">
              <p class="text-lg font-semibold text-center text-gray-700">Call Us Now</p>
              <p
                class="w-40 py-2 font-semibold text-center text-gray-700 transition duration-200 ease-linear rounded-lg hover:text-white hover:bg-sri-peach hover:shadow-md bg-sri-orange">
                <a href="tel:+60162172172">016-217 2172</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- main banner -->
    <MainBanner />

    <div class="h-20"></div>
    <!-- about us -->
    <div class="py-10">
      <div class="container">
        <h1 class="text-2xl font-semibold text-center text-gray-800">
          About Us
        </h1>
        <div class="flex items-center justify-center mt-3 text-sri-orange">
          <div class="bg-sri-orange w-8 h-0.5"></div>
          <i class="px-2 customSize fa-solid fa-circle"></i>
          <div class="bg-sri-orange w-8 h-0.5"></div>
        </div>
        <div class="mt-8 lg:flex lg:items-center">
          <!-- left side -->
          <div class="text-sm text-center text-gray-600 lg:text-left lg:w-1/2">
            <div class="md:w-2/3 md:mx-auto lg:w-96 xl:w-2/3">
              <img src="/images/main-logo.png" alt="" class="" />
            </div>
            <p class="mt-4">
              Established in 1994, <b>Sri Nadin</b> is the leader in the
              provision of domestic and general workers in Malaysia. The process
              can be a challenging one. At <b>Sri Nadin</b> we have extensive
              experience to facilitate a seamless process. We focus on training
              of domestic workers as we recognize this as one of the most
              important factors in the employment process.
            </p>
          </div>
          <!-- right side -->
          <div class="mt-4 lg:w-1/2">
            <img src="/images/about-us.png" alt="" class="md:w-2/3 lg:w-3/4 md:mx-auto" />
          </div>
        </div>
      </div>
    </div>

    <!--  our services -->
    <div class="py-10 bg-gray-100">
      <div class="container">
        <h1 class="text-2xl font-semibold text-center text-gray-900">
          Our Services
        </h1>
        <div class="flex items-center justify-center mt-3 text-sri-orange">
          <div class="bg-sri-orange w-8 h-0.5"></div>
          <i class="px-2 customSize fa-solid fa-circle"></i>
          <div class="bg-sri-orange w-8 h-0.5"></div>
        </div>

        <div class="flex flex-wrap mt-8">
          <div v-for="(item, i) in services" :key="i"
            class="relative w-1/2 p-1 text-gray-600 transition duration-300 ease-linear opacity-90 lg:pb-2 hover:text-gray-900 lg:w-1/3 hover:opacity-100">
            <img :src="item.image" :alt="item.alt" class="rounded shadow" />
            <div class="absolute top-2 left-3">
              <h1 class="text-2xl font-semibold text-white text-shadow">
                {{ item.step }}
              </h1>
            </div>
            <p v-html="item.alt" class="mt-4 text-sm leading-tight text-center lg:leading-tight lg:text-base"></p>
          </div>
        </div>
        <div class="flex mt-5 space-x-1 text-center md:justify-center">
          <h1 class="w-40 py-1 text-gray-800 rounded bg-sri-orange">
            <a href="tel:+60162172172">Call Us Now </a>
          </h1>
          <h1 class="w-40 py-1 text-gray-800 rounded bg-sri-orange">
            <a href="https://wa.me/60162172172">WhatsApp Us</a>
          </h1>
        </div>
      </div>
    </div>

    <div class="py-10 bg-sri-orange">
      <div class="container">
        <h1 class="text-2xl font-semibold text-center text-gray-900">
          If You Are Looking For
        </h1>
        <div class="flex items-center justify-center mt-3 text-gray-900">
          <div class="bg-gray-900 w-8 h-0.5"></div>
          <i class="px-2 customSize fa-solid fa-circle"></i>
          <div class="bg-gray-900 w-8 h-0.5"></div>
        </div>

        <div class="mt-8 md:flex md:flex-wrap">
          <div v-for="(item, i) in special" :key="i" class="w-3/4 p-1 mx-auto md:w-1/3 md:mx-0">
            <div
              class="transition duration-200 ease-linear transform bg-white rounded-md hover:shadow-md hover:scale-105">
              <img :src="item.image" :alt="item.alt" class="rounded-t-md" />
              <div class="py-4 font-medium text-center text-gray-800">
                <h1>{{ item.alt }}</h1>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3 text-center">
          <h1 class="text-2xl font-semibold text-gray-700">
            Special Category Needed?
          </h1>
          <div class="flex justify-center mt-5 space-x-1">
            <h1 class="py-2 text-white bg-gray-800 rounded w-36">
              <a href="tel:+60162172172">Call Us Now</a>
            </h1>
            <h1 class="py-2 text-white bg-gray-800 rounded w-36">
              <a
                href="https://wa.me/60162172172?text=Hi%20there,%20I%20would%20like%20to%20enquiry%20about%20Philippines%20and%20Indonesia%20maid.">WhatsApp
                Us</a>
            </h1>
          </div>
        </div>
      </div>
    </div>

    <!-- why choose us -->
    <div class="py-10">
      <div class="container">
        <h1 class="text-2xl font-semibold text-center text-gray-800">
          Why Choose Us
        </h1>
        <div class="flex items-center justify-center mt-3 text-sri-orange">
          <div class="bg-sri-orange w-8 h-0.5"></div>
          <i class="px-2 customSize fa-solid fa-circle"></i>
          <div class="bg-sri-orange w-8 h-0.5"></div>
        </div>

        <div class="flex flex-wrap mt-8 md:justify-center lg:max-w-4xl lg:mx-auto">
          <div v-for="(item, i) in choose" :key="i" class="w-1/2 p-1 text-center md:w-1/3 lg:w-1/4">
            <div
              class="px-3 py-6 text-gray-600 transition duration-300 ease-in-out border border-gray-300 rounded-md hover:border-sri-peach hover:text-sri-orange hover:shadow">
              <img :src="item.image" :alt="item.alt" class="mx-auto w-28" />
              <div class="mt-3 text-sm h-14 md:h-6 lg:h-8">
                <h1 class="text-sm font-medium leading-tight">
                  {{ item.alt }}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- certificate -->
    <div class="py-10">
      <div class="container">
        <h1 class="text-2xl font-semibold text-center text-gray-800">
          Certificates
        </h1>
        <div class="flex items-center justify-center mt-3 text-sri-orange">
          <div class="bg-sri-orange w-8 h-0.5"></div>
          <i class="px-2 customSize fa-solid fa-circle"></i>
          <div class="bg-sri-orange w-8 h-0.5"></div>
        </div>

        <div class="grid gap-2 mt-8 md:grid-cols-2 lg:grid-cols-3">
          <div v-for="image in certificate" :key="image" class="">
            <img :src="'/images/' + image + '.jpg'" alt="" class="mx-auto">
          </div>
        </div>
      </div>
    </div>

    <!-- testimonials -->
    <div class="py-10 bg-sri-orange">
      <div class="container">
        <h1 class="text-2xl font-semibold text-center text-gray-900">
          Our Testimonials
        </h1>
        <div class="flex items-center justify-center mt-3 text-gray-900">
          <div class="bg-gray-900 w-8 h-0.5"></div>
          <i class="px-2 customSize fa-solid fa-circle"></i>
          <div class="bg-gray-900 w-8 h-0.5"></div>
        </div>

        <div class="mt-8">
          <carousel :autoplay="6500" :loop="true" :responsive="{
            0: { items: 1, nav: false, dots: true },
            768: { items: 2, nav: false, dots: true },
            1024: { items: 3, nav: false, dots: true },
          }">
            <div v-for="(item, i) in testimonials" :key="i" class="px-1">
              <div class="px-3 py-5 text-sm text-center bg-white rounded-md">
                <p class="h-40">
                  {{ item.p }}
                </p>
                <h1 class="mt-6 text-xl text-gray-800">
                  {{ item.client }}
                </h1>
              </div>
            </div>
          </carousel>
        </div>
      </div>
    </div>

    <!-- sub banner -->
    <div class="bg-center bg-no-repeat bg-cover" style="background-image: url('/images/bg-sub.jpg')">
      <div class="container py-10">
        <div class="pt-10">
          <h1 class="text-3xl font-bold leading-tight text-white lg:text-4xl">
            We Provide The Most Professional Services to Your Household Needs
          </h1>
          <div class="w-20 h-0.5 mt-5 bg-sri-orange"></div>
        </div>
        <div class="pt-10 text-center md:text-left md:flex md:items-center">
          <div class="text-white md:w-2/3">
            <h2 class="text-xl font-bold leading-tight md:text-2xl">
              Let Us Maid Up Your Life
            </h2>
          </div>
          <div class="mt-4 md:w-1/3">
            <h1 class="py-2 mx-auto text-lg text-center text-gray-800 md:mx-auto w-52 bg-sri-orange">
              <a href="#" v-scroll-to="{ el: '#element', duration: 1500 }">Get
                A Free Quote</a>
            </h1>
          </div>
        </div>
      </div>
    </div>

    <!-- Enquire form -->
    <div class="bg-gray-100">
      <div class="lg:flex xl:items-center xl:container">
        <div class="px-4 py-10 bg-gray-100 lg:w-1/2">
          <!-- left side -->
          <div id="element" class="lg:py-5">
            <h1 class="text-2xl font-semibold text-center text-gray-800">
              Sent Us An Enquiry
            </h1>
            <div class="flex items-center justify-center mt-3 text-sri-orange">
              <div class="bg-sri-orange w-8 h-0.5"></div>
              <i class="px-2 customSize fa-solid fa-circle"></i>
              <div class="bg-sri-orange w-8 h-0.5"></div>
            </div>
            <EnquiryForm class="mt-6" />
          </div>
        </div>
        <div class="md:w-2/3 lg:w-1/2 md:mx-auto">
          <img src="/images/bg-enquiry.jpg" alt="" class="w-full" />
        </div>
      </div>
    </div>

    <!-- footer -->
    <div class="py-10">
      <div class="container">
        <div>
          <div class="md:w-2/3 lg:w-1/2 md:mx-auto xl:w-1/3">
            <img src="/images/main-logo.png" alt="" class="" />
          </div>
          <div class="mt-5 text-lg font-bold text-center md:text-2xl">
            <h1 class="leading-tight">
              7-B, Level 7, Menara Goldstone, 84 Jalan Raja Chulan, 50200 Kuala
              Lumpur Malaysia
            </h1>
            <p class="mt-4">
              <i class="pr-2 text-2xl fa-solid fa-phone"></i>
              <a href="tel:+60162172172" class="transition duration-300 ease-in-out hover:text-sri-red">016-217 2172</a>
            </p>
            <p><i class="fa-solid fa-building"></i> <a href="tel:+60321647777"
                class="transition duration-300 ease-in-out hover:text-sri-red">03-2164
                7777</a> </p>
          </div>
        </div>
      </div>
    </div>

    <!-- copyright -->

    <div class="py-3 bg-sri-orange">
      <div class="container mx-auto">
        <h1 class="text-xs text-center text-gray-800 lg:text-sm">
          Website maintained by Activa Media. All Rights Reserved.
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import carousel from "vue-owl-carousel";
import EnquiryForm from "@/components/EnquiryForm.vue";
import MainBanner from "@/components/MainBanner.vue";
import WaBtn from "@/components/WaBtn.vue";

export default {
  name: "Home",
  components: {
    carousel,
    EnquiryForm,
    MainBanner,
    WaBtn,
  },
  data() {
    return {
      services: [
        {
          image: "/images/service-1.jpg",
          alt: "Hiring of Domestic Helper From Source Country",
          step: "1.",
        },
        {
          image: "/images/service-2.jpg",
          alt: "Application of Various Working Permits",
          step: "2.",
        },
        {
          image: "/images/service-3.jpg",
          alt: "Application of Visa for MM2H, ESD and etc",
          step: "3.",
        },
        {
          image: "/images/service-4.jpg",
          alt: "Cancellation of Work Permits and Service of Reparation",
          step: "4.",
        },
        {
          image: "/images/service-5.jpg",
          alt: "Renewal: Passport, Work Permits, Employment Contracts.",
          step: "5.",
        },
        {
          image: "/images/service-6.jpg",
          alt: "Counselling and Special Training Program ",
          step: "6.",
        },

        {
          image: "/images/service-8.jpg",
          alt: "Ensuring Of Medical Fit to Work ",
          step: "7.",
        },

        {
          image: "/images/service-10.jpg",
          alt: "Inhouse Orientation and Brainstorming ",
          step: "8.",
        },
        {
          image: "/images/service-11.jpg",
          alt: "Protection for Personal Accident, Medical and Life Insurance ",
          step: "9.",
        },
      ],
      special: [
        { image: "/images/child-care.jpg", alt: "Child Care" },
        { image: "/images/caregiver.jpg", alt: "Caregiver" },
        { image: "/images/house-cleaning.jpg", alt: "House Cleaning " },
      ],
      choose: [
        // {
        //   image: "/images/icon-1.png",
        //   alt: "Back to Back Guarantee Policy",
        // },
        {
          image: "/images/icon-2.png",
          alt: "Exceeding 30 Years in the Business",
        },
        {
          image: "/images/icon-3.png",
          alt: "Satisfied Customers Make Confident Referrals",
        },
        { image: "/images/icon-4.png", alt: "Well Trained Domestic Helpers" },
        { image: "/images/icon-5.png", alt: "Reliable & Fast Processing" },
        { image: "/images/icon-6.png", alt: "Fast Response " },
        { image: "/images/icon-7.png", alt: "Interview Session Available" },
        // {
        //   image: "/images/icon-8.png",
        //   alt: "English Speaking and Muslim Maid Available",
        // },
        // { image: "/images/icon-6.png", alt: "Muslim Maid Available" },
      ],
      testimonials: [
        {
          p: "The team helps a lot, from choosing the maid, to the interview section and they assist to arrange everything quickly, they helped us greatly in getting our maid in on time. I am so lucky to have my maid and my mother is very dependant on my maid.",
          client: "Mrs Lim ",
        },
        {
          p: "This is my first experience with Sri Nadin recommended by my friend, I had a good experience with them, and most importantly hassle-free arrangement in engaging a house helper/maid. They did a good job! Keep it up",
          client: "Datuk Dr. Tan",
        },
        {
          p: "Very good customer service and responsiveness whenever assistance needed. Highly recommended.",
          client: "Dato Seri Andy",
        },
        {
          p: "I have been using this Agency for more than 15 years. My maids are good and I am lucky they work very long with me. I am happy with the Agency. Recommended to many of my friends",
          client: "Datin Sri Lee",
        },
        {
          p: "Highly efficient, very responsible team. Overall, I’m satisfied with the service provided in direct hiring my helper. Worth to pay. Thanks",
          client: "Dato’ Arif",
        },
        {
          p: "Fiona is very efficient and knowledgeable person. The process was smooth. Thank you very much. Highly recommend.",
          client: "Datin Hazel Sian",
        },
        {
          p: "Their staff is really responsible and quick in action. It's my first time here and I am impressed with their service. Will definitely recommend Sri Nadin to my friends.",
          client: "Dr. S.K Lau",
        },
        {
          p: "My long term Agency. Trustworthy. All my maids from here for the past 20 years. Good Agency. Surely can recommend to others.",
          client: "Datin Mohd Noor",
        },
        {
          p: "Thank you Fiona for a job welldone. Very friendly and personable as well! Really appreciative of your wonderful service throughout the whole hiring process. I have maid to take care of my mum now, appreciated.",
          client: "Mr Tan",
        },
        {
          p: "At last I managed to get my maid in. Wow it is a long processing. Sincerely thank you Sri Nadin. I can concentrate at my office work now.",
          client: "Mrs Phang",
        },
        {
          p: "My replacement maid is very good. Thank you for getting me a better maid. ",
          client: "Tengku Mohd",
        },
        {
          p: "Trustworthiness, experienced, knowledgeable in handling all my maids. Continue so - Sri Nadin. ",
          client: "Tan Sri Dato Seri Wong",
        },
      ],
      one_stop: [
        { image: "/images/one-1.png", alt: "1. Procurement" },
        { image: "/images/one-2.png", alt: "2. Recruitment" },
        { image: "/images/one-3.png", alt: "3. Selection" },
        { image: "/images/one-4.png", alt: "4. Processing Application" },
        { image: "/images/one-5.png", alt: "5. Handling" },
        { image: "/images/one-6.png", alt: "6. Arrival" },
        { image: "/images/one-7.png", alt: "7. Servicing" },
        { image: "/images/one-8.png", alt: "8. Renewal" },
        { image: "/images/one-9.png", alt: "9. Settlement" },
      ],
      process: [
        {
          step: "STEP 1",
          p: "Fact Finding",
        },
        {
          step: "STEP 2",
          p: "Initial List of Pre-Qualified Candidates",
        },
        {
          step: "STEP 3",
          p: "Novation Resources Interview",
        },
        {
          step: "STEP 4",
          p: "Open Disclosure",
        },
        {
          step: "STEP 5",
          p: "Final Shortlist",
        },
        {
          step: "STEP 6",
          p: "Client Interview",
        },
        {
          step: "STEP 7",
          p: "3A's: Application, Approval, Arrival",
        },
      ],
      certificate:[
        'certificate-1',
        'certificate-2',
        'certificate-3',
      ]
    };
  },
};
</script>

<style>
.text-shadow {
  text-shadow: 2px 2px 3px #2d2d2d;
}

.customSize {
  font-size: 7px;
}

.customHeight {
  height: 30rem;
}

@media (min-width: 768px) {
  .customHeight {
    height: 16rem;
  }
}
</style>
